import React, { useState } from 'react';
import {
    Edit,
    SimpleForm,
    TextField,
    TextInput,
    SelectInput,
    required,
    minLength,
    regex,
    useTranslate,
    Toolbar,
    ListButton,
    SaveButton,
    DeleteButton,
    TopToolbar,
    usePermissions,
} from 'react-admin'
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { REGEX_PASSWORD } from '../../constant';

const isEmpty = value => typeof value === 'undefined' || value === null || value === '';

const compareField = field => (value, allValues) => !isEmpty(value) && value !== allValues[field] ? 'validate.compare' : undefined;

const validate = [required()];
const validatePassword = [required(), minLength(8), regex(REGEX_PASSWORD, 'validate.password')];
const validateConfirmPassword = [required(), compareField('password')];

const useStyles = makeStyles({
    defaultToolbar: {
        flex: 1,
        display: 'flex',
        justifyContent: 'space-between',
    },
});

const getCurrentEmail = ({ loaded, permissions }) => loaded && permissions ? permissions.email : null;

const UserDeleteButton = ({ showDelete, ...props }) => showDelete && <DeleteButton {...props} />

const EditToolbar = props => {
    const classes = useStyles();
    const userPermissions = usePermissions();
    const currentEmail = getCurrentEmail(userPermissions);
    const showDelete = !!props.record && !!props.record.email && !!currentEmail && currentEmail !== props.record.email;

    return (
        <Toolbar className={classes.defaultToolbar} {...props} >
            <SaveButton />
            <UserDeleteButton showDelete={showDelete} />
        </Toolbar>
    );
};

const EditActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        <ListButton basePath={basePath} record={data} />
    </TopToolbar>
);

const UserEdit = props => {
    const [showPassword, setShowPassword] = useState(false);
    const translate = useTranslate();
    return (
        <Edit undoable={false} actions={<EditActions />} {...props}>
            <SimpleForm toolbar={<EditToolbar />}>
                <TextField source="email" />
                {showPassword && <TextInput source="password" type="password" validate={validatePassword} autoComplete="new-password" />}
                {showPassword && <TextInput source="confirmPassword" type="password" validate={validateConfirmPassword} autoComplete="new-password" />}
                <Button variant="contained" color="primary" onClick={() => setShowPassword(!showPassword)}>{translate(showPassword ? 'resources.users.hidePassword' : 'resources.users.updatePassword')}</Button>
                <TextInput source="firstname" validate={validate} />
                <TextInput source="lastname" validate={validate} />
                <SelectInput source="role" choices={[
                    { id: 'user', name: 'enum.role.user' },
                    { id: 'user_all', name: 'enum.role.user_all' },
                    { id: 'admin', name: 'enum.role.admin' },
                ]} validate={validate} />
            </SimpleForm>
        </Edit>
    );
};

export default UserEdit;