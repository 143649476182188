import List from './List';
import Icon from 'mdi-material-ui/GraveStone';

const Cemetries = {
    name: 'cemeteries',
    options: {
        section: 'general',
    },
    icon: Icon,
    list: List,
}

export default Cemetries;