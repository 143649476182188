import List from './List';
import Create from './Create';
import Edit from './Edit';
import Icon from '@material-ui/icons/Person';

const Users = {
    name: 'users',
    options: {
        section: 'setting',
        role: 'admin',
    },
    icon: Icon,
    list: List,
    create: Create,
    edit: Edit,
}

export default Users;