import React, { useCallback } from 'react';
import {
    List,
    Responsive,
    SimpleList,
    Datagrid,
    TextField,
    BooleanField,
    EditButton,
    useRedirect,
    Filter,
    TextInput,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles';
import { Switch, Route } from 'react-router';
import { Dialog } from '@material-ui/core';
import ActionsWithCreate from '../../components/ActionsWithCreate';
import CreateName from '../../components/CreateName';
import Edit from './Edit';

const CemetriesFilter = (props) => (
    <Filter {...props}>
        <TextInput source="name" alwaysOn />
    </Filter>
);

const useStyles = makeStyles(theme => ({
    row: {
        '& td:nth-child(1n+2)': {
            width: 10,
        }
    },
    paper: {
        width: 400,
        padding: theme.spacing(2)
    },
}));

const CemetriesList = props => {
    const basePath = props.basePath;
    const classes = useStyles();
    const redirect = useRedirect().bind(null, basePath);
    const handleClose = useCallback(() => redirect(), [redirect]);
    return (
        <Switch>
            <Route exact path={[basePath, `${basePath}/create`]} render={
                () => (
                    <>
                        <List bulkActionButtons={false} sort={{ field: 'name', order: 'ASC' }} actions={<ActionsWithCreate />} filters={<CemetriesFilter />} {...props}>
                            <Responsive
                                small={
                                    <SimpleList
                                        primaryText={record => record.title}
                                    />
                                }
                                medium={
                                    <Datagrid rowClick="edit" classes={{ table: classes.row }}>
                                        <TextField source="name" />
                                        <BooleanField source="published" />
                                        <EditButton />
                                    </Datagrid>
                                }
                            />
                        </List>
                        <Route
                            path={`${basePath}/create`}
                            render={() => (
                                <Dialog
                                    open
                                    onClose={handleClose}
                                    classes={{ paper: classes.paper }}>
                                    <CreateName
                                        onCancel={handleClose}
                                        {...props}
                                    />
                                </Dialog>
                            )}
                        />
                    </>
                )
            } />
            <Route path={`${basePath}/:id`}>
                {({ match }) => {
                    const isMatch =
                        (match &&
                            match.params &&
                            match.params.id !== 'create') || false;

                    return isMatch ? (
                        <Edit
                            id={isMatch ? match.params.id : null}
                            {...props}
                        />
                    ) : null;
                }}
            </Route>
        </Switch>
    );
};

export default CemetriesList;