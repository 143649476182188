import React from 'react';
import { Create, SimpleForm, TextInput, SelectInput, required, minLength, email, regex } from 'react-admin';
import { REGEX_PASSWORD } from '../../constant';

const isEmpty = value => typeof value === 'undefined' || value === null || value === '';

const compareField = field => (value, allValues) => !isEmpty(value) && value !== allValues[field] ? 'validate.compare' : undefined;

const validate = [required()];
const validatePassword = [required(), minLength(8), regex(REGEX_PASSWORD, 'validate.password')];
const validateConfirmPassword = [required(), compareField('password')];
const validateEmail = [required(), email()];

const UserCreate = props => (
    <Create undoable={false} {...props}>
        <SimpleForm>
            <TextInput source="email" type="email" validate={validateEmail} />
            <TextInput source="password" type="password" validate={validatePassword} autoComplete="new-password" />
            <TextInput source="confirmPassword" type="password" validate={validateConfirmPassword} autoComplete="new-password" />
            <TextInput source="firstname" validate={validate} />
            <TextInput source="lastname" validate={validate} />
            <SelectInput source="role" choices={[
                { id: 'user', name: 'enum.role.user' },
                { id: 'user_all', name: 'enum.role.user_all' },
                { id: 'admin', name: 'enum.role.admin' },
            ]} validate={validate} />
        </SimpleForm>
    </Create>
);

export default UserCreate;