import List from './List';
import Icon from '@material-ui/icons/LocalOffer';

const Tags = {
    name: 'tags',
    options: {
        section: 'setting',
        role: 'admin',
    },
    icon: Icon,
    list: List,
}

export default Tags;