import React from 'react';
import {
    Create, SimpleForm, TextInput, required, SaveButton, Toolbar, translate,
    useRedirect,
    useDataProvider,
} from 'react-admin';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import firebase from 'firebase'
import { firebaseApp } from '../App';

const style = theme => ({
    defaultToolbar: {
        flex: 1,
        display: 'flex',
        justifyContent: 'space-between',
    },
});

const CreateToolbar = withStyles(style)(translate(({ onCancel, translate, classes, ...props }) => {
    const dataProvider = useDataProvider();
    const redirect = useRedirect();
    const onSuccess = ({ data }) => {
        dataProvider.getOne('cemeteries', { id: data.cemeteryId })
            .then(({ data: cemetery }) => dataProvider.update('cemeteries', {
                id: data.cemeteryId,
                data: {
                    ...cemetery,
                    tombIds: [
                        ...(cemetery.tombIds || []),
                        data.id,
                    ]
                },
                previousData: data
            }))
            .then(() => redirect('edit', '/tombs', data.id))
        //redirect('edit', '/tombs', data.id);
    }


    return (
        <Toolbar className={classes.defaultToolbar} {...props}>
            <SaveButton onSuccess={onSuccess} />
            <Button onClick={onCancel}>{translate('ra.action.cancel')}</Button>
        </Toolbar>
    );
}));

const validate = [required()];

const CreateName = ({ onCancel, cemeteryId, ...props }) => {
    return (
        <Create title=" " basePath="/tombs" resource="tombs">
            <SimpleForm initialValues={{ cemeteryId }} toolbar={<CreateToolbar onCancel={onCancel} />}>
                <TextInput source="name" validate={validate} />
            </SimpleForm>
        </Create>
    )
};

export default CreateName;