import React, { cloneElement } from 'react';
import { TopToolbar, CreateButton, ExportButton, sanitizeListRestProps } from 'react-admin'

const ActionsWithCreate = ({
    bulkActions,
    currentSort,
    className,
    resource,
    filters,
    displayedFilters,
    exporter,
    filterValues,
    permanentFilter,
    hasCreate,
    basePath,
    selectedIds,
    onUnselectItems,
    showFilter,
    total,
    ...rest
}) => (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {bulkActions &&
                cloneElement(bulkActions, {
                    basePath,
                    filterValues,
                    resource,
                    selectedIds,
                    onUnselectItems,
                })}
            {filters &&
                cloneElement(filters, {
                    resource,
                    showFilter,
                    displayedFilters,
                    filterValues,
                    context: 'button',
                })}
            <CreateButton basePath={basePath} />
            {exporter !== false && (
                <ExportButton
                    disabled={total === 0}
                    resource={resource}
                    sort={currentSort}
                    filter={{ ...filterValues, ...permanentFilter }}
                    exporter={exporter}
                />
            )}
        </TopToolbar>
    );

export default ActionsWithCreate;