import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
    MuiThemeProvider,
    createMuiTheme,
    withStyles,
    createStyles,
} from '@material-ui/core/styles';

import { Notification, LoginForm as DefaultLoginForm, defaultTheme } from 'react-admin';

const styles = theme => createStyles({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        height: '1px',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    card: {
        minWidth: 300,
        marginTop: '6em',
    },
    avatar: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        backgroundColor: theme.palette.secondary[500],
        width: 200,
        height: 200,
    },
});

const sanitizeRestProps = ({
    array,
    backgroundImage,
    classes,
    className,
    location,
    staticContext,
    theme,
    title,
    ...rest
}) => rest;

class Login extends Component {
    constructor(props) {
        super(props);
        this.theme = createMuiTheme(props.theme);
    }

    render() {
        const { classes, className, loginForm, ...rest } = this.props;

        return (
            <>
                <CssBaseline />
                <MuiThemeProvider theme={this.theme}>
                    <div
                        className={classnames(classes.main, className)}
                        {...sanitizeRestProps(rest)}
                    >
                        <Card className={classes.card}>
                            <div className={classes.avatar}>
                                <Avatar className={classes.icon} src="/img/logo.png" />
                            </div>
                            {loginForm}
                        </Card>
                        <Notification />
                    </div>
                </MuiThemeProvider>
            </>
        );
    }
}

Login.propTypes = {
    authProvider: PropTypes.func,
    classes: PropTypes.object,
    className: PropTypes.string,
    input: PropTypes.object,
    loginForm: PropTypes.element,
    meta: PropTypes.object,
    previousRoute: PropTypes.string,
};

Login.defaultProps = {
    theme: defaultTheme,
    loginForm: <DefaultLoginForm />,
};

const MyLogin = withStyles(styles)(Login);
export default MyLogin;