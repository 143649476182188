import React from 'react';
import { List, Responsive, Datagrid, SimpleList, TextField, EmailField } from 'react-admin';

const UserList = props => (
    <List bulkActionButtons={false} sort={{ field: 'username', order: 'ASC' }} {...props}>
        <Responsive
            small={
                <SimpleList
                    primaryText={record => record.email}
                    secondaryText={record => `${record.firstname} ${record.lastname}`}
                />
            }
            medium={
                <Datagrid rowClick="edit">
                    <EmailField source="email" />
                    <TextField source="firstname" />
                    <TextField source="lastname" />
                    <TextField source="role" />
                </Datagrid>
            }
        />

    </List>
);

export default UserList;