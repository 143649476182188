import React from 'react';
import 'typeface-roboto';
import { Admin, Resource } from "react-admin";
import frenchMessages from 'ra-language-french';
import { createTheme } from '@material-ui/core/styles';
import {
  FirebaseDataProvider,
  FirebaseAuthProvider,
} from 'react-admin-firebase';
import firebase from 'firebase';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

import * as i18n from './i18n'
import { MAPBOX_TOKEN } from './constant';
import Login from './components/Login';
import Menu from './components/Menu';

import Tombs from './Resources/Tombs'
import Cemetries from './Resources/Cemetries'
import Users from './Resources/Users'
import Honours from './Resources/Honours'
import Tags from './Resources/Tags'

import { firebaseConfig } from './FIREBASE_CONFIG';

mapboxgl.accessToken = MAPBOX_TOKEN;

export const firebaseApp = firebase.initializeApp(firebaseConfig);

const options = {
  logging: true,
  app: firebaseApp,
  persistence: 'session',
  dontAddIdFieldToDoc: true,
  useFileNamesInStorage: true,
};
const dataProvider = FirebaseDataProvider(firebaseConfig, options);
const authProvider = FirebaseAuthProvider(firebaseConfig, options);

const allMessages = {
  'fr': { ...frenchMessages, ...i18n.fr },
};


const i18nProvider = polyglotI18nProvider(
  newLocale => allMessages[newLocale] || allMessages.fr
  ,
  'fr',
  { allowMissing: true },
);

const theme = createTheme({
  palette: {
    secondary: {
      light: '#001e5e',
      main: '#26448c',
      dark: '#5b6fbd',
      contrastText: '#ffffff',
    },
  },
});


const App = () => (
  <Admin
    theme={theme}
    menu={Menu}
    loginPage={Login}
    i18nProvider={i18nProvider}
    dataProvider={dataProvider}
    authProvider={authProvider}
  >
    <Resource {...Tombs} />
    <Resource {...Cemetries} />
    <Resource {...Users} />
    <Resource {...Tags} />
    <Resource {...Honours} />
  </Admin>
);

export default App;
