import React, { useCallback } from 'react';
import { List, Responsive, SimpleList, Datagrid, TextField, EditButton, DeleteButton, useRedirect } from 'react-admin';
import { Route } from 'react-router';
import { Dialog } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CreateName from './CreateName';
import ActionsWithCreate from './ActionsWithCreate';

const useStyles = makeStyles(theme => ({
    row: {
        '& td:nth-child(1n+2)': {
            width: 10,
        }
    },
    paper: {
        width: 400,
        padding: theme.spacing(2),
    },
}));

export default Edit => props => {
    const basePath = props.basePath;
    const classes = useStyles();
    const redirect = useRedirect().bind(null, basePath);
    const handleClose = useCallback(() => redirect(), [redirect]);
    return (
        <>
            <List bulkActionButtons={false} sort={{ field: 'name', order: 'ASC' }} actions={<ActionsWithCreate />} {...props}>
                <Responsive
                    small={
                        <SimpleList
                            primaryText={record => record.name}
                        />
                    }
                    medium={
                        <Datagrid rowClick="edit" classes={{ table: classes.row }}>
                            <TextField source="name" />
                            <EditButton />
                            <DeleteButton />
                        </Datagrid>
                    }
                />
            </List>
            <Route
                path={`${basePath}/create`}
                render={() => (
                    <Dialog
                        open
                        onClose={handleClose}
                        classes={{ paper: classes.paper }}>
                        <CreateName
                            onCancel={handleClose}
                            {...props}
                        />
                    </Dialog>
                )}
            />
            <Route path={`${basePath}/:id`}>
                {({ match }) => {
                    const isMatch =
                        (match &&
                            match.params &&
                            match.params.id !== 'create') || false;

                    return (
                        <Dialog
                            open={isMatch}
                            onClose={handleClose}
                            classes={{ paper: classes.paper }}
                        >
                            {isMatch ? (
                                <Edit
                                    id={isMatch ? match.params.id : null}
                                    onCancel={handleClose}
                                    {...props}
                                />
                            ) : null}
                        </Dialog>
                    );
                }}
            </Route>
        </>
    );
}