import React from 'react';
import PropTypes from 'prop-types';
import inflection from 'inflection';
import classnames from 'classnames';
import { getResources, MenuItemLink, DashboardMenuItem, Responsive, useTranslate } from 'react-admin';

import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import DefaultIcon from '@material-ui/icons/ViewList';
import SettingsIcon from '@material-ui/icons/ExpandMore';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles(theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    sectionSetting: {
        marginTop: 40,
        marginBottom: 5,
        color: theme.palette.text.secondary,
        display: 'flex',
        alignItems: 'flex-start',
        opacity: 1,
    },
    icon: { paddingRight: '1.2em' },
}));


const translatedResourceName = (resource, translate) =>
    translate(`resources.${resource.name}.name`, {
        smart_count: 2,
        _:
            resource.options && resource.options.label
                ? translate(resource.options.label, {
                    smart_count: 2,
                    _: resource.options.label,
                })
                : inflection.humanize(inflection.pluralize(resource.name)),
    });

const Menu = ({
    dense,
    onMenuClick,
    logout,
    className,
    hasDashboard,

    // classes,
    // className,
    // hasDashboard,

    // open,
    // pathname,
    // resources,
    // translate,
    // logout,
    ...rest
}) => {
    const resources = useSelector(getResources);

    const sectionGeneral = resources.filter(resource => resource.options.section === 'general');
    const sectionSetting = resources.filter(resource => resource.options.section === 'setting');
    const classes = useStyles();
    const translate = useTranslate();
    return (
        <div className={classnames(classes.main, className)} {...rest}>
            {hasDashboard && <DashboardMenuItem onClick={onMenuClick} />}
            {sectionGeneral
                .filter(r => r.hasList)
                .map(resource => (
                    <MenuItemLink
                        key={resource.name}
                        to={`/${resource.name}`}
                        primaryText={translatedResourceName(resource, translate)}
                        leftIcon={
                            resource.icon ? <resource.icon /> : <DefaultIcon />
                        }
                        onClick={onMenuClick}
                        dense={dense}
                    />
                ))}
            {sectionSetting.length > 0 && (<MenuItem disabled className={classes.sectionSetting}><span className={classes.icon}><SettingsIcon /></span>{translate('sections.setting')}</MenuItem>)}
            {sectionSetting
                .filter(r => r.hasList)
                .map(resource => (
                    <MenuItemLink
                        key={resource.name}
                        to={`/${resource.name}`}
                        primaryText={translatedResourceName(resource, translate)}
                        leftIcon={
                            resource.icon ? <resource.icon /> : <DefaultIcon />
                        }
                        onClick={onMenuClick}
                        dense={dense}
                    />
                ))}
            <Responsive xsmall={logout} medium={null} />
        </div>
    );
}

Menu.propTypes = {
    className: PropTypes.string,
    dense: PropTypes.bool,
    hasDashboard: PropTypes.bool,
    logout: PropTypes.element,
    onMenuClick: PropTypes.func,
};

Menu.defaultProps = {
    onMenuClick: () => null,
};


export default Menu;