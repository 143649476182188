import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
    Edit,
    TabbedForm,
    FormTab,
    TextInput,
    BooleanInput,
    useInput,
    useTranslate,
    FieldTitle,
    InputHelperText,
    ArrayInput,
    SimpleFormIterator,
    SelectInput,
    FormDataConsumer,
    ImageInput,
    FileInput,
    ImageField,
    FileField,
    ReferenceArrayInput,
    CheckboxGroupInput,
} from 'react-admin';
import firebase from 'firebase'
import { makeStyles } from '@material-ui/core/styles';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import RichTextInput from 'ra-input-rich-text';
import { MAPBOX_STYLE, RICH_TEXT_INPUT_TOOLBAR } from '../../constant';
import TextField from '@material-ui/core/TextField';
import { useForm } from 'react-final-form';

const useStyles = makeStyles(theme => ({
    name: {
        width: '100%',
    },
}));

const LocationInput = props => {
    const { label, helperText, resource, source } = props;
    const {
        meta: { touched, error },
        isRequired
    } = useInput(props);
    const form = useForm();
    const currentState = form.getState().values['location'];

    console.log(props.record);

    const handleChange = useCallback(
        event => {
            const value = event.target.value ? parseFloat(event.target.value) : 0;
            const old = form.getState().values['location'] || new firebase.firestore.GeoPoint(0, 0);
            if (event.target.name === 'latitude')
                form.change(source, new firebase.firestore.GeoPoint(value, old.longitude));
            else {
                form.change(source, new firebase.firestore.GeoPoint(old.latitude, value));
            }
        },
        [form, source]
    );

    return (
        <>
            <TextField
                name="latitude"
                onChange={handleChange}
                label={
                    label !== '' &&
                    label !== false && (
                        <FieldTitle
                            label={label}
                            source={`${source}.latitude`}
                            resource={resource}
                            isRequired={isRequired}
                        />
                    )
                }
                error={!!(touched && error)}
                helperText={
                    <InputHelperText
                        touched={touched}
                        error={error}
                        helperText={helperText}
                    />
                }
                variant="filled"
                margin="dense"
                style={{ width: '200px', marginRight: '20px' }}
                type="number"
                inputProps={{
                    value: currentState ? currentState.latitude : 0
                }}
            />
            <TextField
                name="longitude"
                onChange={handleChange}
                label={
                    label !== '' &&
                    label !== false && (
                        <FieldTitle
                            label={label}
                            source={`${source}.longitude`}
                            resource={resource}
                            isRequired={isRequired}
                        />
                    )
                }
                error={!!(touched && error)}
                helperText={
                    <InputHelperText
                        touched={touched}
                        error={error}
                        helperText={helperText}
                    />
                }
                variant="filled"
                margin="dense"
                style={{ width: '200px', marginRight: '20px' }}
                type="number"
                inputProps={{
                    value: currentState ? currentState.longitude : 0
                }}
            />
        </>
    )
}

const LocationMap = props => {
    const input = useInput(props);
    const form = useForm();
    const location = form.getState().values['location'];
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [mapLoaded, setMapLoaded] = useState(false);

    const center = location || { latitude: 48.887166, longitude: 2.299390 };

    useEffect(() => {
        const m = new mapboxgl.Map({
            container: mapContainer.current,
            style: MAPBOX_STYLE,
            center: [center.longitude, center.latitude],
            zoom: 17
        });

        m.on('load', () => {
            m.addSource('pin', {
                type: 'geojson',
                data: {
                    "type": "FeatureCollection",
                    "features": [
                        {
                            "type": "Feature",
                            "geometry": {
                                "type": "Point",
                                "coordinates": [center.longitude, center.latitude],
                            },
                        },
                    ]
                }
            });
            m.addLayer({
                'id': 'pin',
                'type': 'symbol',
                'source': 'pin', // reference the data source
                'layout': {
                    'icon-allow-overlap': true,
                    'icon-image': 'pin-normal',
                }
            });


            setMapLoaded(true)
        });

        map.current = m;
    }, [])

    useEffect(() => {
        if (mapLoaded) {
            map.current.setCenter([center.longitude, center.latitude])
            map.current.getSource('pin').setData({
                "type": "FeatureCollection",
                "features": [
                    {
                        "type": "Feature",
                        "geometry": {
                            "type": "Point",
                            "coordinates": [center.longitude, center.latitude],
                        },
                    },
                ],
            });
        }

    }, [center, mapLoaded])

    return <div ref={mapContainer} style={{ height: 600, width: '100%' }}></div>
}

const TombTitle = ({ record }) => {
    return <span>Sépulture {record ? `"${record.name}"` : ''}</span>;
};

const TombEdit = props => {
    const classes = useStyles();
    const translate = useTranslate();

    return (
        <Edit undoable={false} title={<TombTitle />} {...props}>
            <TabbedForm>
                <FormTab label={translate('tabs.summary')}>
                    <TextInput source="name" className={classes.name} />
                    <TextInput source="conflit" className={classes.name} />
                    <TextInput source="grade" className={classes.name} />
                    <TextInput source="matricule" className={classes.name} />
                    <TextInput source="emplacement" className={classes.name} />
                    <ReferenceArrayInput source="honourIds" reference="honours">
                        <CheckboxGroupInput optionText="name" />
                    </ReferenceArrayInput>
                    <BooleanInput source="notMPLF" />
                    <BooleanInput source="published" />
                    <LocationInput source="location" />
                    <LocationMap source="location" />
                </FormTab>
                <FormTab path="contents" label={translate('tabs.contents')}>
                    <ArrayInput source="contents" label=" ">
                        <SimpleFormIterator>
                            <SelectInput source="type" required choices={[
                                { id: 'TEXT', name: 'Texte' },
                                { id: 'IMAGES', name: 'Images' },
                                { id: 'AUDIO', name: 'Audio' },
                                { id: 'VIDEO', name: 'Vidéo' },
                            ]} />
                            <FormDataConsumer label="Texte">
                                {({ formData, scopedFormData = {}, getSource, ...rest }) => {
                                    const type = scopedFormData.type || '';
                                    getSource('type');
                                    if (type !== 'TEXT') return <></>;

                                    return <RichTextInput source={getSource('text')} toolbar={RICH_TEXT_INPUT_TOOLBAR} {...rest} />
                                }}
                            </FormDataConsumer>
                            <FormDataConsumer label="Audio">
                                {({ formData, scopedFormData = {}, getSource, ...rest }) => {
                                    const type = scopedFormData.type || '';
                                    getSource('type');
                                    if (type !== 'AUDIO') return <></>;

                                    return (
                                        <FileInput source={getSource('urlSrc')} accept="audio/*" maxSize={5000000} {...rest}>
                                            <FileField source="src" title="title" />
                                        </FileInput>
                                    );
                                }}
                            </FormDataConsumer>
                            <FormDataConsumer label="Vidéo">
                                {({ formData, scopedFormData = {}, getSource, ...rest }) => {
                                    const type = scopedFormData.type || '';
                                    getSource('type');
                                    if (type !== 'VIDEO') return <></>;

                                    return (
                                        <FileInput source={getSource('urlSrc')} accept="video/mp4,video/x-m4v,video/*" maxSize={5000000} {...rest}>
                                            <FileField source="src" title="title" />
                                        </FileInput>
                                    );
                                }}
                            </FormDataConsumer>
                            <FormDataConsumer label="Miniature">
                                {({ formData, scopedFormData = {}, getSource, ...rest }) => {
                                    const type = scopedFormData.type || '';
                                    getSource('type');
                                    if (type !== 'AUDIO' && type !== 'VIDEO') return <></>;

                                    return (
                                        <ImageInput source={getSource('thumbnailSrc')} accept="image/*" maxSize={1000000} {...rest}>
                                            <ImageField source="src" title="title" />
                                        </ImageInput>
                                    );
                                }}
                            </FormDataConsumer>
                            <FormDataConsumer label=" ">
                                {({ formData, scopedFormData = {}, getSource, ...rest }) => {
                                    const type = scopedFormData.type || '';
                                    getSource('type');
                                    if (type !== 'IMAGES') return <></>;

                                    return (
                                        <ArrayInput source={getSource('images')} label=" " >
                                            <SimpleFormIterator>
                                                <ImageInput source='image' accept="image/*" maxSize={1000000} {...rest} >
                                                    <ImageField source="src" title="title" />
                                                </ImageInput>
                                                <TextInput source="title" label="Titre" className={classes.name} />
                                                <TextInput source="credits" label="Crédits" className={classes.name} />
                                            </SimpleFormIterator>
                                        </ArrayInput>
                                    );
                                }}
                            </FormDataConsumer>

                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
            </TabbedForm>
        </Edit>
    );
}

export default TombEdit;