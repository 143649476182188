import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    BooleanField,
    DeleteButton,
    Filter,
    TextInput
} from 'react-admin';

const TombsFilter = (props) => (
    <Filter {...props}>
        <TextInput source="name" alwaysOn />
    </Filter>
);

export default props => (
    <List bulkActionButtons={false} sort={{ field: 'name', order: 'ASC' }} filters={<TombsFilter />} {...props}>
        <Datagrid rowClick="edit">
            <TextField source="name" />
            <BooleanField source="published" />
            <EditButton />
        </Datagrid>
    </List>
);