const fr = {
    resources: {
        users: {
            name: 'Utilisateur |||| Utilisateurs',
            updatePassword: 'Mots de passe',
            hidePassword: 'Masqué',
            fields: {
                firstname: 'Prénom',
                lastname: 'Nom',
                email: 'Email',
                cemeteries: 'Cimetières',
                role: 'role',
                username: 'Identifiant',
                password: 'Mots de passe',
                confirmPassword: 'Confirmation du mots de passe',
            }
        },
        tags: {
            name: 'Tag |||| Tags',
            fields: {
                name: 'Nom',
            }
        },
        cemeteries: {
            name: 'Cimetière |||| Cimetières',
            fields: {
                name: 'Nom',
                information: 'Information',
                openingHours: 'Horaire d\'ouverture',
                published: 'Publié',
                image: 'Image'
            },
        },
        tombs: {
            name: 'Sépulture |||| Sépultures',
            fields: {
                name: 'Nom',
                notMPLF: 'Non mort pour la france',
                published: 'Publié',
                type: 'Type',
                honourIds: 'Médailles'
            },
        },
        honours: {
            name: 'Médaille |||| Médailles',
        }
    },
    enum: {
        role: {
            admin: 'Administrateur',
            user_all: 'Utilisateur (tous les cimetières)',
            user: 'Utilisateur',
        }
    },
    validate: {
        password: 'Doit contenir au moins une majuscule, une minuscule, un chiffre et un caractère spécial',
        login: 'Ne doit pas contenir d\'espace',
    },
    sections: {
        setting: 'Administration'
    },
    tabs: {
        summary: 'Information',
        tombs: 'Sépultures',
        contents: 'Contenu',
        photos: 'Photos'
    }
}

export default fr;