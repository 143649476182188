import React from 'react';
import { Edit, SimpleForm, TextInput, required, SaveButton, Toolbar, useTranslate } from 'react-admin';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    defaultToolbar: {
        flex: 1,
        display: 'flex',
        justifyContent: 'space-between',
    },
});

const TagEditToolbar = ({ onCancel, ...props }) => {
    const classes = useStyles();
    const translate = useTranslate();
    return (
        <Toolbar className={classes.defaultToolbar} {...props}>
            <SaveButton />
            <Button onClick={onCancel}>{translate('ra.action.cancel')}</Button>
        </Toolbar>
    );
};

const validate = [required()];

const EditName = ({ onCancel, ...props }) => (
    <Edit undoable={false} title=" " {...props}>
        <SimpleForm toolbar={<TagEditToolbar onCancel={onCancel} />}>
            <TextInput source="name" validate={validate} />
        </SimpleForm>
    </Edit>
);

export default EditName;