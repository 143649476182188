import React from 'react';
import { Create, SimpleForm, TextInput, required, SaveButton, Toolbar, translate } from 'react-admin';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';

const style = theme => ({
    defaultToolbar: {
        flex: 1,
        display: 'flex',
        justifyContent: 'space-between',
    },
});

const CreateToolbar = withStyles(style)(translate(({ onCancel, translate, classes, ...props }) => (
    <Toolbar className={classes.defaultToolbar} {...props}>
        <SaveButton />
        <Button onClick={onCancel}>{translate('ra.action.cancel')}</Button>
    </Toolbar>
)));

const validate = [required()];

const CreateName = ({ onCancel, ...props }) => (
    <Create title=" " {...props}>
        <SimpleForm toolbar={<CreateToolbar onCancel={onCancel} />}>
            <TextInput source="name" validate={validate} />
        </SimpleForm>
    </Create>
);

export default CreateName;