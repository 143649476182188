import Edit from './Edit';
import List from './List';
import Icon from 'mdi-material-ui/GraveStone';

export default {
    name: 'tombs',
    options: {
        section: 'general',
    },
    icon: Icon,
    list: List,
    edit: Edit,
}