import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import {
    Edit,
    TabbedForm,
    FormTab,
    TextInput,
    BooleanInput,
    BooleanField,
    Datagrid,
    TextField,
    useGetList,
    ListContextProvider,
    Loading,
    useTranslate,
    EditButton,
    ArrayInput,
    SimpleFormIterator,
    ImageInput,
    ImageField,
    CreateButton,
    useRedirect,
} from 'react-admin';
import keyBy from 'lodash/keyBy'
import { makeStyles } from '@material-ui/core/styles';
import RichTextInput from 'ra-input-rich-text';
import { MAPBOX_STYLE, RICH_TEXT_INPUT_TOOLBAR } from '../../constant';
import { Route } from 'react-router';
import { Dialog } from '@material-ui/core';
import CreateName from '../../components/CreateNameTomb';

const useStyles = makeStyles(theme => ({
    name: {
        width: '100%',
    },
    tabTombs: {
        '& > div': {
            display: 'flex',
            flexDirection: 'row',
        },
        '& > div > div': {
            width: '50%',
        }
    },
    arrayTombs: {
        width: '100%',

    },
    paper: {
        width: 400,
        padding: theme.spacing(2)
    },
}));

const Tombs = props => {
    const { data, loading, } = useGetList(
        'tombs',
        { page: 1, perPage: 100000 },
        { field: 'name', order: 'ASC' }
    );
    const result = ((props.record || {}).tombIds || []).map(id => data[id]).filter(d => d);

    if (loading) return <Loading />;
    return (
        <>
            <div>
                <ListTombs data={result} basePath={props.basePath} id={props.record.id} />
            </div>
            <MapTombs data={result.filter(t => t.location)} />
        </>
    );
}

const ListTombs = memo(({ basePath, id, data }) => {
    return (
        <>
            <ListContextProvider value={{
                data: keyBy(data, 'id'),
                ids: data.map(({ id }) => id),
                total: data.length,
                page: 1,
                perPage: null,
                setPage: null,
                currentSort: { field: 'id', order: 'ASC' },
                basePath: "/tombs",
                resource: 'tombs',
                selectedIds: []
            }}>
                <Datagrid rowClick="edit">
                    <TextField source="name" />
                    <BooleanField source="published" />
                    <EditButton />
                </Datagrid>
            </ListContextProvider >
            <CreateButton basePath={`${basePath}/${id}/tombs`} />
        </>
    );
});

const MapTombs = memo(({ data }) => {
    if (data.length === 0) return <div />;

    const coordinates = data.map(t => ([
        t.location.longitude,
        t.location.latitude,
    ]));
    const geojson = {
        "type": "FeatureCollection",
        "features": data.map(t => ({
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    t.location.longitude,
                    t.location.latitude,
                ]
            },
            "properties": {
                "id": t.id,
                "selected": false,
            },
        })),
    };
    const bounds = coordinates.reduce((b, coord) => b.extend(coord), new mapboxgl.LngLatBounds(coordinates[0], coordinates[0])).toArray();

    console.log(geojson, bounds);
    return <MapLite geojson={geojson} bounds={bounds} />
})

const MapLite = memo(({ geojson, bounds }) => {
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [mapLoaded, setMapLoaded] = useState(false);

    const center = [
        (bounds[0][0] + bounds[1][0]) / 2,
        (bounds[0][1] + bounds[1][1]) / 2
    ];

    useEffect(() => {
        const m = new mapboxgl.Map({
            container: mapContainer.current,
            style: MAPBOX_STYLE,
            center: center,
            zoom: 17
        });

        m.on('load', () => {
            m.addSource('pin', {
                type: 'geojson',
                data: geojson,
            });
            m.addLayer({
                'id': 'pin',
                'type': 'symbol',
                'source': 'pin', // reference the data source
                'layout': {
                    'icon-allow-overlap': true,
                    'icon-image': 'pin-normal',
                }
            });

            setMapLoaded(true)
        });

        map.current = m;
    }, [])

    useEffect(() => {
        if (mapLoaded) {
            map.current.setCenter(center)
            map.current.getSource('pin').setData(geojson);
        }

    }, [center, mapLoaded, geojson])

    return <div ref={mapContainer} style={{ height: 600, width: '100%' }}></div>;
});

const CemetriesTitle = ({ record }) => {
    return <span>Cimetière {record ? `"${record.name}"` : ''}</span>;
};

const CemetriesEdit = props => {
    const classes = useStyles();
    const translate = useTranslate();
    const basePath = props.basePath;
    const redirect = useRedirect().bind(null, `${basePath}/${props.id}/tombs`);
    const handleClose = useCallback(() => redirect(), [redirect]);
    return (
        <>
            <Edit undoable={false} title={<CemetriesTitle />} {...props}>
                <TabbedForm>
                    <FormTab label={translate('tabs.summary')}>
                        <TextInput source="name" className={classes.name} />
                        <RichTextInput source="information" toolbar={RICH_TEXT_INPUT_TOOLBAR} />
                        <RichTextInput source="openingHours" toolbar={RICH_TEXT_INPUT_TOOLBAR} />
                        <BooleanInput source="published" />
                    </FormTab>
                    <FormTab path="tombs" label={translate('tabs.tombs')} contentClassName={classes.tabTombs}>
                        <Tombs />
                    </FormTab>
                    <FormTab path="photos" label={translate('tabs.photos')}>
                        <ArrayInput source="images" label=" " >
                            <SimpleFormIterator>
                                <ImageInput source='image' accept="image/*" maxSize={1000000} >
                                    <ImageField source="src" title="title" />
                                </ImageInput>
                                <TextInput source="title" label="Titre" className={classes.name} />
                                <TextInput source="credits" label="Crédits" className={classes.name} />
                            </SimpleFormIterator>
                        </ArrayInput>
                    </FormTab>
                </TabbedForm>
            </Edit>
            <Route
                path={`${basePath}/:id/tombs/create`}
                render={() => (
                    <Dialog
                        open
                        onClose={handleClose}
                        classes={{ paper: classes.paper }}>
                        <CreateName
                            onCancel={handleClose}
                            cemeteryId={props.id}
                            {...props}
                        />
                    </Dialog>
                )}
            />
        </>
    );
}

export default CemetriesEdit;